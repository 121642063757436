.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000ad;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .circular-loading {
    color: white;
  }
}

.disable {
  display: none !important;
}