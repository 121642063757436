#header {
  display: flex;
  justify-content: center;

  border-bottom: 1px solid #e4e4e4;

  .logo {
    width: 75px;
    height: 50px;
    
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}