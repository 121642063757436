#token-info-box {

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px;
    width: 100%;
  }

  .number {
    color: #51A9F6;
    font-weight: bold;
    font-size: 30px;
  }

  .title {
    font-weight: bold;
    font-size: 17px;
  }
}