html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a, .MuiLink-root {
  color: inherit;
  text-decoration: none !important;
}

.MuiLink-root:hover {
  text-decoration: underline !important;
}

* {
  box-sizing: border-box;
}

.space {
  margin-top: 30px;
}

.btm-space {
  margin-left: 15px !important;
}

@import './Header.module.scss';
// @import './Home.module.scss';
@import './TokenInfoBox.module.scss';
@import './Loading.module.scss';